import React, { useEffect } from 'react';
import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './Style.scss';

const OrderConfirmation = () => {
  const navigate = useNavigate();

  // Simulate loading or waiting for the order confirmation to complete
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/orders'); // Redirect to home after a short delay
    }, 3000); // Redirect after 3 seconds
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [navigate]);

  const handleBackToHome = () => {
    navigate('/orders'); // Redirect to home immediately
  };

  return (
    <Box p={3} width="100%" maxWidth="600px" mx="auto" textAlign="center">
      {/* Animated Checkmark Icon */}
      <CheckCircleIcon sx={{ fontSize: 100, color: 'green', animation: 'spin 2s linear infinite' }} />

      <Typography variant="h4" align="center" mt={2}>
        Thank You for Your Order!
      </Typography>

      <Typography variant="body1" align="center" mb={3}>
        Your order has been placed successfully and is being processed.
      </Typography>

      {/* Loading Spinner (optional) */}
      <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />

      {/* Button to go back to the Home page */}
      <Button variant="contained" color="primary" onClick={handleBackToHome}>
        Back to Home
      </Button>
    </Box>
  );
};

export default OrderConfirmation;
