import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Drawer,
  Badge,
  IconButton,
  CircularProgress,
  Modal,
  Skeleton,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";
import CartDrawer from "./CartDrawer";
import { Helmet } from "react-helmet";

const ProductCard = ({ product, onAddToCart, onQuickView }) => {
  const [isAdded, setIsAdded] = useState(false);

  const handleAddClick = () => {
    onAddToCart(product);
    setIsAdded(true);
    setTimeout(() => setIsAdded(false), 500); // Reset "Added" state after 500ms
  };

  return (
    <div className="product-card-container">
      <Box padding={2} textAlign="left" sx={{ boxShadow: 3, borderRadius: 2 }}>
        <img
          className="product-image"
          src={product.image}
          alt={product.title}
          style={{ width: "100%", height: "200px", objectFit: "cover" }}
          onClick={() => onQuickView(product)}
        />
        <Typography
          className="product-title"
          variant="h6"
          mt={1}
          style={{ fontSize: "18px", fontWeight: "600" }}
        >
          {product.title}
        </Typography>
        <Typography className="product-weight" variant="body2" color="textSecondary">
          {product.weight}
        </Typography>
        <Typography className="product-price" variant="h6" color="primary">
          रु. <span style={{ textDecoration: "line-through" }}>{product.MRP}</span>{" "}
          <span style={{ color: "green" }}> रु.{product.discountedPrice}</span>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleAddClick}
          style={{ marginTop: "10px" }}
          className="product-button"
        >
          {isAdded ? "Product Added" : "Add to Cart"}
        </Button>
      </Box>
    </div>
  );
};

const FashionProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [quickViewProduct, setQuickViewProduct] = useState(null);

  useEffect(() => {
    // Fetch products from API
    axios
      .get("https://grocista.com/api/categories/category/fashion")
      .then((response) => {
        setProducts(response.data);
        setLoading(false); // Disable loading state
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setLoading(false); // Disable loading even if there is an error
      });
  }, []);

  const handleAddToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.productId === product.productId);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.productId === product.productId ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const handleIncrement = (clickedProduct) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item === clickedProduct ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (clickedProduct) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item === clickedProduct && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const toggleCartDrawer = () => setIsCartOpen((prev) => !prev);

  const openQuickView = (product) => setQuickViewProduct(product);
  const closeQuickView = () => setQuickViewProduct(null);

  return (
    <>
    <Helmet>
      <title>
        Fashion Products || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <Box>
      <div style={{ marginBottom: "55px" }}>
        <div
          className="header-container"
          style={{ margin: "4%", display: "flex", justifyContent: "space-around" }}
        >
          <Typography variant="h5">Fashion Products</Typography>
          <a href="/" style={{}}>Home</a>
        </div>
        {loading ? (
          <Grid container spacing={2} p={2}>
            {Array.from(new Array(8)).map((_, index) => (
              <Grid item xs={6} sm={6} md={3} lg={3} key={index}>
                <Skeleton variant="rectangular" height={250} />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container spacing={2} p={2}>
            {products.map((product) => (
              <Grid item xs={6} sm={6} md={3} lg={3} key={product.productId}>
                <ProductCard
                  product={product}
                  onAddToCart={handleAddToCart}
                  onQuickView={openQuickView}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {/* Cart Drawer */}
        <Drawer anchor="right" open={isCartOpen} onClose={toggleCartDrawer}>
          <CartDrawer
            cart={cart}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
          />
        </Drawer>

        {/* Quick View Modal */}
        {quickViewProduct && (
          <Modal open={Boolean(quickViewProduct)} onClose={closeQuickView}>
            <Box
              sx={{
                width: 400,
                margin: "auto",
                marginTop: "10vh",
                padding: 4,
                backgroundColor: "white",
                boxShadow: 24,
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                {quickViewProduct.title}
              </Typography>
              <img
                src={quickViewProduct.image}
                alt={quickViewProduct.title}
                style={{ width: "100%", height: "200px", objectFit: "cover" }}
              />
              <Typography mt={2}>{quickViewProduct.description}</Typography>
              <Typography variant="h6" color="primary" mt={2}>
                रु. {quickViewProduct.discountedPrice}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  handleAddToCart(quickViewProduct);
                  closeQuickView();
                }}
                style={{ marginTop: "10px" }}
              >
                Add to Cart
              </Button>
            </Box>
          </Modal>
        )}

        <div className="floating-cart__container">
          <IconButton
            color="primary"
            className="floating-cart"
            onClick={toggleCartDrawer}
            aria-label="View Cart"
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              backgroundColor: "#007bff",
              color: "#fff",
              borderRadius: "50%",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
            }}
          >
            <Badge
              className="live-cart"
              badgeContent={cart.reduce((sum, item) => sum + item.quantity, 0)}
              color="secondary"
            >
              <ShoppingCartIcon style={{ fontSize: "2rem" }} />
            </Badge>
          </IconButton>
        </div>
      </div>
    </Box>
    </>
  );
};

export default FashionProducts;
