import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  IconButton, Typography, Paper, Collapse, Box, CircularProgress,
  Alert, Divider
} from '@mui/material';
import { Cancel, ExpandMore, ExpandLess } from '@mui/icons-material';
import axios from 'axios';
import './Style.scss'; // Ensure the styles complement the improved UI
import { Helmet } from 'react-helmet';

const Orders = ({ userPhone }) => {
  const [orders, setOrders] = useState([]);
  const [expandedOrder, setExpandedOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userPhone) return; // Exit if no phone number is provided

    const fetchOrders = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`https://grocista.com/api/orders?userPhone=${userPhone}`);
        if (Array.isArray(response.data)) {
          setOrders(response.data);
        } else {
          setError('No orders found for this user.');
        }
      } catch (err) {
        setError('Failed to fetch orders. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userPhone]);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const response = await axios.put(`https://grocista.com/api/orders/${orderId}`, {
        status: 'Cancelled',
      });
      if (response.data) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.orderId === orderId ? { ...order, status: 'Cancelled' } : order
          )
        );
        alert('Order has been cancelled successfully.');
      }
    } catch (err) {
      alert('Failed to cancel the order.');
    }
  };

  if (loading) {
    return <CircularProgress sx={{ display: 'block', margin: 'auto', mt: 5 }} />;
  }

  if (error) {
    return <Alert severity="error" sx={{ margin: '20px' }}>{error}</Alert>;
  }

  if (orders.length === 0) {
    return (
      <Typography variant="h6" sx={{ textAlign: 'center', mt: 5 }}>
        No Orders Found
      </Typography>
    );
  }

  return (
    <>
    <Helmet>
      <title>
        Orders|| Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <div className="orders-view" style={{minHeight: "120vh"}}>
      <Box sx={{ mt: 3, px: 2 }}>
        <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
          Your Orders
        </Typography>
        <Divider sx={{ mb: 3 }} />
        
        <div className="orders-table" style={{minHeight: "120vh"}}>
          <TableContainer component={Paper} className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell><strong>Address</strong></TableCell> */}
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell><strong>Status</strong></TableCell>
                  <TableCell><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <React.Fragment key={order.orderId}>
                    <TableRow hover>
                      {/* <TableCell className="table-cell">{order.userStreet}</TableCell> */}
                      <TableCell className="table-cell">रु. {order.totalPrice?.toFixed(2) || '0.00'}</TableCell>
                      <TableCell className="table-cell">{order.status || 'Pending'}</TableCell>
                      <TableCell className="table-cell">
                        <div className="icon-buttons">
                          <IconButton onClick={() => toggleOrderDetails(order.orderId)}>
                            {expandedOrder === order.orderId ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>

                    {/* Expanded row for order details */}
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                        <Collapse in={expandedOrder === order.orderId} timeout="auto" unmountOnExit>
                          <Box margin={2}>
                            <Typography variant="subtitle1" gutterBottom>
                              Order Details
                            </Typography>
                            <Table size="small" className='table-heads'>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Items</TableCell>
                                  <TableCell>Weight</TableCell>
                                  <TableCell>Price</TableCell>
                                  <TableCell>Total</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order.products.map((product) => (
                                  <TableRow key={product.productId}>
                                    <TableCell>{product.title}</TableCell>
                                    <TableCell>{product.quantity}</TableCell>
                                    <TableCell>रु. {product.price?.toFixed(2) || '0.00'}</TableCell>
                                    <TableCell>
                                      रु. {(product.price * product.quantity).toFixed(2) || '0.00'}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </div>
    </>
  );
};

export default Orders;
