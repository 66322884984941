import React, { useState } from 'react';
import { TextField, Button, Avatar, Typography, Container, Box, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Save, Cancel, Edit } from '@mui/icons-material';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const AccountSettings = ({ userDetails }) => {
  const [editableDetails, setEditableDetails] = useState({ ...userDetails });
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await axios.put('/api/user', editableDetails);
      alert('User details updated successfully!');
      setOpenDialog(false);
    } catch (error) {
      alert('Error updating details');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditableDetails(userDetails);  // Reset to original user data
    setOpenDialog(false);
  };

  return (
    <>
    <Helmet>
      <title>
        Account Settings || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <Container sx={{ mt: 3 }}>
      <Paper sx={{ padding: 3, boxShadow: 3 }}>
        <Box textAlign="center">
          <Avatar
            alt="User Avatar"
            src="https://image.sparkhuemall.com/wp-content/uploads/2024/11/Grocista-2-1.png"
            sx={{ width: 100, height: 100, margin: '0 auto' }}
          />
          <Typography variant="h5" gutterBottom>
            {userDetails.name}
          </Typography>
          <Typography variant="h6">{userDetails.address}</Typography>
          <Button
            onClick={() => setOpenDialog(true)}
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            sx={{ mt: 2 }}
          >
            Edit Details
          </Button>
        </Box>

        <Dialog open={openDialog} onClose={handleCancel}>
          <DialogTitle>Edit Your Details</DialogTitle>
          <DialogContent>
            <TextField
              label="Full Name"
              variant="outlined"
              name="name"
              value={editableDetails.name}
              onChange={handleChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Address"
              variant="outlined"
              name="address"
              value={editableDetails.address}
              onChange={handleChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              value={editableDetails.password}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="default" disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" disabled={isLoading}>
              {isLoading ? 'Saving...' : 'Save Changes'}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
    </>
  );
};

export default AccountSettings;
