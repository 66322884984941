import React, { useState } from 'react';
import { TextField, Button, MenuItem, Box, Paper, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';  // For improved notification handling
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockIcon from '@mui/icons-material/Lock';
import { Helmet } from 'react-helmet';

const SignUp = () => {
    const [formData, setFormData] = useState({
        userName: '',
        userPhone: '',
        zone: '',
        userStreet: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        userPhone: '',
        password: '',
    });

    const zones = [
        'Mechi', 'Koshi', 'Sagarmatha', 'Janakpur', 'Narayani',
        'Bagmati', 'Gandaki', 'Lumbini', 'Dhawalagiri', 
        'Rapti', 'Karnali', 'Bheri', 'Seti', 'Mahakali'
    ];

    const { enqueueSnackbar } = useSnackbar();  // For displaying notifications
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validateForm = () => {
        const newErrors = {
            userPhone: '',
            password: '',
        };

        // Validate Phone Number (must be 10 digits)
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.userPhone)) {
            newErrors.userPhone = 'Phone number must be 10 digits';
        }

        // Validate Password (must be at least 5 characters)
        if (formData.password.length < 5) {
            newErrors.password = 'Password must be at least 5 characters';
        }

        setErrors(newErrors);

        return !Object.values(newErrors).some(error => error);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        // Handle zone-specific notifications
        if (formData.zone !== 'Koshi') {
            enqueueSnackbar('Currently, we are not available in this zone, but we will be soon! You can still open an account.', { variant: 'info' });
        }

        try {
            const response = await axios.post('https://grocista.com/api/signup', formData);
            enqueueSnackbar(response.data.message, { variant: 'success' });

            // Clear form fields
            setFormData({
                userName: '',
                userPhone: '',
                zone: '',
                userStreet: '',
                password: '',
            });

            // Redirect to Sign In page
            navigate('/');
        } catch (error) {
            enqueueSnackbar('Error signing up. Please try again.', { variant: 'error' });
        }
    };

    return (
        <>
        <Helmet>
      <title>
        Register Page || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
        <Paper
            elevation={3}
            sx={{
                maxWidth: 450,
                mx: 'auto',
                mt: 5,
                px: 4,
                py: 6,
                borderRadius: 3,
                bgcolor: 'background.paper',
            }}
        >
            <Typography variant="h5" align="center" sx={{ mb: 3, fontWeight: 600 }}>
                Sign Up
            </Typography>
            
            <form onSubmit={handleSubmit}>
                {/* User Name Field */}
                <TextField
                    fullWidth
                    label="User Name"
                    name="userName"
                    value={formData.userName}
                    onChange={handleChange}
                    margin="normal"
                    required
                    InputProps={{
                        startAdornment: <PersonIcon sx={{ color: 'action.active', mr: 1 }} />,
                    }}
                />
                
                {/* User Phone Field with Validation */}
                <TextField
                    fullWidth
                    label="User Phone"
                    name="userPhone"
                    type="tel"
                    value={formData.userPhone}
                    onChange={handleChange}
                    margin="normal"
                    required
                    error={!!errors.userPhone}
                    helperText={errors.userPhone}
                    InputProps={{
                        startAdornment: <PhoneIcon sx={{ color: 'action.active', mr: 1 }} />,
                    }}
                />
                
                {/* Zone Field */}
                <TextField
                    fullWidth
                    select
                    label="Zone"
                    name="zone"
                    value={formData.zone}
                    onChange={handleChange}
                    margin="normal"
                    required
                >
                    {zones.map((zone, index) => (
                        <MenuItem key={index} value={zone}>
                            {zone}
                        </MenuItem>
                    ))}
                </TextField>
                
                {/* Street Address Field */}
                <TextField
                    fullWidth
                    label="Street Address"
                    name="userStreet"
                    value={formData.userStreet}
                    onChange={handleChange}
                    margin="normal"
                    required
                    InputProps={{
                        startAdornment: <LocationOnIcon sx={{ color: 'action.active', mr: 1 }} />,
                    }}
                />
                
                {/* Password Field with Validation */}
                <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                    margin="normal"
                    required
                    error={!!errors.password}
                    helperText={errors.password}
                    InputProps={{
                        startAdornment: <LockIcon sx={{ color: 'action.active', mr: 1 }} />,
                    }}
                />
                
                {/* Submit Button */}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                        mt: 2,
                        py: 1.5,
                        textTransform: 'none',
                        boxShadow: 3,
                        '&:hover': {
                            backgroundColor: '#007B3A',
                            boxShadow: 6,
                        },
                    }}
                >
                    Sign Up
                </Button>
                
                {/* Sign In Button */}
                <Button
                    href='/'
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                        mt: 2,
                        py: 1.5,
                        textTransform: 'none',
                        borderColor: '#007B3A',
                        '&:hover': {
                            borderColor: '#00592D',
                            backgroundColor: '#E8F5E9',
                        },
                    }}
                >
                    Sign In
                </Button>
            </form>
        </Paper>
        </>
    );
};

export default SignUp;
