import React, { useState } from "react";
import { Box, Typography, TextField, MenuItem, Button, Paper } from "@mui/material";
import axios from "axios";
import styles from './SupportPage.module.scss';  // Import the SCSS file
import { Helmet } from "react-helmet";

const SupportPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    issue: "",
    description: "",
  });

  const issueOptions = ["Order History", "Placing Order", "Delivery Issues", "Other"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.number || !formData.issue || !formData.description) {
      alert("Please fill in all the fields.");
      return;
    }

    try {
      const response = await axios.post("https://grocista.com/api/support", formData);
      alert("Support request submitted successfully!");
      console.log(response.data);
      setFormData({ name: "", number: "", issue: "", description: "" });
    } catch (error) {
      console.error("Error submitting support request:", error);
      alert("Failed to submit support request. Please try again.");
    }
  };

  return (
    <>
    <Helmet>
      <title>
        Support || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <div style={{marginTop: "20px", minHeight: "110vh"}}>
    <Box className={styles.supports}>
      <Paper elevation={3} className={styles.paper}>
        <Typography variant="h4" className={styles.title}>
          Support
        </Typography>
        <Typography variant="body1" className={styles.description}>
          Need help? Fill out the form below and we’ll get back to you.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            className={styles['form-field']}
          />
          <TextField
            label="Phone Number"
            name="number"
            value={formData.number}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            className={styles['form-field']}
          />
          <TextField
            select
            label="Select an Issue"
            name="issue"
            value={formData.issue}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
            className={styles['form-field']}
          >
            {issueOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Describe Your Issue"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            fullWidth
            required
            multiline
            rows={4}
            margin="normal"
            className={styles['form-field']}
          />
          <Button variant="contained" color="primary" type="submit" className={styles['submit-button']}>
            Submit
          </Button>
        </form>
      </Paper>
    </Box>
    </div>
    </>
  );
};

export default SupportPage;
