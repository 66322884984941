import React from "react";
import AllProductsPage from "../components/AllProductsPage";
import CategoryCards from "../components/CategoryCards";
import { Helmet } from "react-helmet";

const DashboardHome = ({ userName }) => {
  return (
    <>
    <Helmet>
      <title>
        Dashboard || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <CategoryCards />
    <AllProductsPage />
    </>
  );
};

export default DashboardHome;
