
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

// Import pages and components
import SignUp from "./pages/Register/RegisterUser";
import DashboardHome from "./pages/DashboardHome";
import AccountSettings from "./pages/AccountSettings";
import SupportPage from "./pages/SupportPage";
import Orders from "./pages/Orders";
import SignIn from "./pages/LoginPage";
import Layout from "./layout/Layout";
import CheckoutPage from "./components/CheckoutPage";
import OrderConfirmation from "./components/OrderConfirmation";

import KiranaProducts from "./components/KiranaProducts";
import FruitsProducts from "./components/FruitsProducts";
import VegProducts from "./components/VegProducts";
import NonVegProducts from "./components/NonVegProducts";
import NotificationPage from "./components/NotificationsPage";
import CosmeticsProducts from "./components/CosmeticsProducts";
import FashionProducts from "./components/FashionProducts";
import NailsProducts from "./components/NailsProducts";
import SkinCareProducts from "./components/SkinCareProducts";
import JuiceProducts from "./components/JuiceProducts";
import MomosProducts from "./components/MomosProducts";
import JewelleryProducts from "./components/JewelleryProducts";
import EarringsProducts from "./components/EarringsProducts";

const BASE_URL = "https://grocista.com/api";

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userOrders, setUserOrders] = useState([]);
  const [loading, setLoading] = useState(true); // To handle loading state

  // Load user from localStorage and set loggedInUser state
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setLoggedInUser(parsedUser);  // Set loggedInUser state from localStorage
      fetchUserOrders(parsedUser.userPhone); // Fetch orders for the logged-in user
    }
    setLoading(false); // Set loading to false once user data is checked
  }, []);

  // Fetch user orders based on phone number
  const fetchUserOrders = async (userPhone) => {
    if (!userPhone) return; // Avoid fetching if user phone is not available
    try {
      const response = await axios.get(`${BASE_URL}/orders`, { params: { userPhone } });
      if (Array.isArray(response.data)) {
        setUserOrders(response.data);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  // Handle Login - Save user to localStorage and fetch user orders
  const handleLogin = async (userData) => {
    setLoggedInUser(userData);
    localStorage.setItem("user", JSON.stringify(userData)); // Save user in localStorage
    fetchUserOrders(userData.userPhone); // Fetch orders for the user
  };

  // Handle Logout - Remove user from localStorage and reset user data
  const handleLogout = () => {
    setLoggedInUser(null);
    setUserOrders([]);
    localStorage.removeItem("user"); // Clear user session from localStorage
  };

  // Protected Route component
  const ProtectedRoute = ({ element }) => {
    return loggedInUser ? element : <Navigate to="/signin" />;
  };

  // Avoid rendering routes until the user data is loaded
  if (loading) {
    return <div>Loading...</div>; // Placeholder for loading spinner or message
  }

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="register" element={<SignUp />} />
        <Route path="signin" element={<SignIn onLogin={handleLogin} />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <DashboardHome />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/account-settings"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <AccountSettings userDetails={loggedInUser} />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/orders"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <Orders userPhone={loggedInUser?.userPhone} />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <SupportPage />
                </Layout>
              }
            />
          }
        />
        
        <Route
          path="/notifications"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <NotificationPage />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <CheckoutPage />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/order-confirmation"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <OrderConfirmation />
                </Layout>
              }
            />
          }
        />

        {/* Product Routes */}
        <Route
          path="/category/kirana-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <KiranaProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/fruits-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <FruitsProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/veg-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <VegProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/nonveg-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <NonVegProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/cosmetics-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <CosmeticsProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/fashion-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <FashionProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/nails-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <NailsProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/skincare-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <SkinCareProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/juice-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <JuiceProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/momos-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <MomosProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/jewellery-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <JewelleryProducts />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/category/earrings-products"
          element={
            <ProtectedRoute
              element={
                <Layout user={loggedInUser} onLogout={handleLogout}>
                  <EarringsProducts />
                </Layout>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
