import React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Box, Container } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link for navigation
import fruits from '../images/fruits.png'
import kirana from '../images/kirana.avif'
import veg from '../images/veg.jpg'
import nonveg from '../images/non-veg.jpg'
import cosmetics from '../images/Cosmetics.jpeg'
import fashion from '../images/fashion.webp'
import earrings from '../images/Earrings.jpg'
import jewellery from '../images/Jewellery.webp'
import juice from '../images/juice.jpg'
import momos from '../images/momos.jpg'
import nails from '../images/nails.jpg'
import SkinCare from '../images/Skin-Care.webp'
import './Style.scss'

const CategoryCards = () => {
  return (
    <div className='cards-container'>
      <Typography variant='h4' className="text-center">Top Category</Typography>
      <Container>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={2}>
            {/* Vegetables Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/veg-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={veg}
                    alt="Vegetables"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Vegetables</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Non-Veg Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/nonveg-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={nonveg}
                    alt="Non-Veg"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Non-Veg</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Fruits Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/fruits-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={fruits}
                    alt="Fruits"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Fruits</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>

            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/kirana-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={kirana}
                    alt="Kirana"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Kirana</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/fashion-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={fashion}
                    alt="Fashion"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Fashion</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/nails-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={nails}
                    alt="Nails"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Nails</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/skincare-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={SkinCare}
                    alt="Skin Care"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Skin Care</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/cosmetics-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={cosmetics}
                    alt="Cosmetics"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Cosmetics</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/juice-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={juice}
                    alt="Juice"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Juice</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/momos-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={momos}
                    alt="Momos"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Momos</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/jewellery-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={jewellery}
                    alt="Jewellery"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Jewellery</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            
            {/* Kirana Category Card */}
            <Grid item xs={6} sm={3} md={3}>
              <Link to="/category/earrings-products" className='mobile-card-category' style={{ textDecoration: 'none' }}>
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    image={earrings}
                    alt="Earrings"
                  />
                  <CardContent>
                    <Typography className="mobile-text-category">Earrings</Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default CategoryCards;
