import React, { useState } from "react";
import {
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Box,
  // Badge,
  Container,
  BottomNavigation,
  BottomNavigationAction,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Home, ShoppingCart, Help, ChevronLeft, Menu as MenuIcon, AccountCircle, Notifications, Settings } from "@mui/icons-material";
import "./Style.scss";

const Layout = ({ children, user }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [value, setValue] = useState(0); // For active tab tracking


  const navigate = useNavigate();

  // Sidebar toggle
  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  // Profile menu handlers
  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Clear session
    navigate("/signin"); // Redirect to sign-in page
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const routes = ["/", "/orders", "/support", "/notifications", "/account-settings"];
    navigate(routes[newValue]);
  };

  return (
    <div className={`layout ${isSidebarOpen ? "layout--expanded" : "layout--collapsed"}`}>
      {/* Sidebar */}
      <Drawer
        variant="permanent"
        open={isSidebarOpen}
        sx={{
          width: isSidebarOpen ? 240 : 70,
          "& .MuiDrawer-paper": {
            width: isSidebarOpen ? 240 : 70,
            transition: "width 0.3s ease",
          },
        }}
        className="mobile-drawer"
      >
        {/* User Info */}
        <Box display="flex" justifyContent="space-between" alignItems="center" p={1}>
          {isSidebarOpen && (
            <Box display="flex" alignItems="center">
              <Avatar
                alt={user?.name || "Grocista"}
                src={user?.avatar || "https://image.sparkhuemall.com/wp-content/uploads/2024/11/Grocista-2-1.png"}
              />
              <Typography ml={1}>{user?.name || "User"}</Typography>
            </Box>
          )}
          <IconButton onClick={toggleSidebar}>
            {isSidebarOpen ? <ChevronLeft /> : <MenuIcon />}
          </IconButton>
        </Box>
        <Divider />

        {/* Navigation Links */}
        <List>
          <ListItem button component={Link} to="/">
            <Home />
            {isSidebarOpen && <ListItemText primary="Home" />}
          </ListItem>
          <ListItem button component={Link} to="/orders">
            <ShoppingCart />
            {isSidebarOpen && <ListItemText primary="Orders" />}
          </ListItem>
          <ListItem button component={Link} to="/support">
            <Help />
            {isSidebarOpen && <ListItemText primary="Support" />}
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content Area */}
      <Box flexGrow={1}>
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" flexGrow={1}>
              Hello, {user?.name || "User"}!
            </Typography>
            <Box>
              <IconButton color="inherit" onClick={handleProfileMenuClick}>
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleMenuClose}>
                <MenuItem component={Link} to="/account-settings">
                  Profile Settings
                </MenuItem>
                <MenuItem component={Link} to="/notifications">Notifications</MenuItem>
                <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Container>{children}</Container>
      </Box>

      <div className="mobile-layout">
        {/* Footer with Tabs */}
        <BottomNavigation
          value={value}
          onChange={handleTabChange}
          showLabels
          className="mobile-tab-layout"
          sx={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <BottomNavigationAction label="Home" icon={<Home />} />
          <BottomNavigationAction label="Orders" icon={<ShoppingCart />} />
          <BottomNavigationAction label="Support" icon={<Help />} />
          <BottomNavigationAction label="Notifications" icon={<Notifications />} />
          <BottomNavigationAction label="Settings" icon={<Settings />} />
        </BottomNavigation>
      </div>

    </div>
  );
};

export default Layout;
