import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Stepper, Step, StepLabel, Button, TextField, FormControl, RadioGroup, FormControlLabel, Radio, Divider, Paper } from '@mui/material';
import axios from 'axios';
import CartSummary from './CartSummary';
import './Style.scss';

const CheckoutPage = ({ loggedInUser }) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [cart, setCart] = useState(state?.cart || []);
  const [activeStep, setActiveStep] = useState(0);
  const [userDetails, setUserDetails] = useState({
    name: loggedInUser?.name || '',
    phone: loggedInUser?.mobileNumber || '',
    street: loggedInUser?.street || '',
  });
  const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem('paymentMethod') || '');

  const steps = ['User Details', 'Payment Method', 'Review & Place Order'];

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('paymentMethod');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleIncrement = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.productId === productId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (productId) => {
    setCart((prevCart) =>
      prevCart
        .map((item) =>
          item.productId === productId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const handleRemove = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.productId !== productId));
  };

  const handlePlaceOrder = () => {
    const orderData = {
      userPhone: userDetails.phone,
      userName: userDetails.name,
      userStreet: userDetails.street,
      paymentMethod: paymentMethod,
      products: cart.map((item) => ({
        productId: item.productId,
        title: item.title,
        quantity: item.quantity,
        price: Number(item.discountedPrice),
      })),
      totalPrice: cart.reduce((sum, item) => sum + Number(item.discountedPrice) * item.quantity, 0),
    };

    axios.post('https://grocista.com/api/place-order', orderData)
      .then((response) => {
        console.log('Order placed:', response.data);
        navigate('/order-confirmation');
      })
      .catch((error) => {
        console.error('Error placing order:', error);
        alert('Failed to place order. Please try again.');
      });
  };

  return (
    <>
      <div className="checkout-container">
        <Box p={3}>
          <Typography variant="h4" align="center" gutterBottom>Checkout</Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Typography variant="h6">Order Summary</Typography>
                <Divider sx={{ my: 1 }} />
                <CartSummary
                  cart={cart}
                  handleIncrement={handleIncrement}
                  handleDecrement={handleDecrement}
                  handleRemove={handleRemove}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box p={2} mt={2} className="checkout-step-box">
                {activeStep === 0 && (
                  <Box display="flex" flexDirection="column" gap={2} className="user-details-form">
                    <TextField
                      label="Name"
                      name="name"
                      value={userDetails.name}
                      onChange={handleInputChange}
                      fullWidth
                      required
                      className="form-field"
                    />
                    <TextField
                      label="Phone Number"
                      name="phone"
                      value={userDetails.phone}
                      onChange={handleInputChange}
                      fullWidth
                      required
                      className="form-field"
                    />
                    <TextField
                      label="Street Address"
                      name="street"
                      value={userDetails.street}
                      onChange={handleInputChange}
                      fullWidth
                      required
                      className="form-field"
                    />
                  </Box>
                )}

                {activeStep === 1 && (
                  <FormControl component="fieldset" className="payment-method-form">
                    <Typography variant="h6" mb={2} className="payment-method-title">Select Payment Method</Typography>
                    <RadioGroup name="paymentMethod" value={paymentMethod} onChange={handlePaymentChange} className="payment-method-radio-group">
                      <FormControlLabel value="Cash On Delivery" control={<Radio />} label="Cash On Delivery" />
                      <FormControlLabel value="eSewa" control={<Radio />} label="eSewa" />
                      <FormControlLabel value="Fonepay" control={<Radio />} label="Fonepay" />
                      <FormControlLabel value="Khalti" control={<Radio />} label="Khalti" />
                    </RadioGroup>
                  </FormControl>
                )}

                {activeStep === 2 && (
                  <Box className="order-review-box">
                    <Typography variant="h6" mb={2}>Review Order</Typography>
                    <Typography variant="body1">Name: {userDetails.name}</Typography>
                    <Typography variant="body1">Phone: {userDetails.phone}</Typography>
                    <Typography variant="body1">Street: {userDetails.street}</Typography>
                    <Typography variant="body1">Payment Method: {paymentMethod}</Typography>
                    <Divider sx={{ my: 2 }} />
                  </Box>
                )}
              </Box>

              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                {activeStep === steps.length - 1 ? (
                  <Button variant="contained" color="primary" onClick={handlePlaceOrder}>Place Order</Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    disabled={
                      (activeStep === 0 && (!userDetails.name || !userDetails.phone || !userDetails.street)) ||
                      (activeStep === 1 && !paymentMethod)
                    }
                  >
                    Next
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default CheckoutPage;
