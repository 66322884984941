// CartDrawer.jsx
import React, { useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton, Divider, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

// In CartDrawer.jsx
const CartDrawer = ({ cart, handleIncrement, handleDecrement }) => {
  const navigate = useNavigate();

  // Calculate total price
  const totalPrice = cart.reduce((sum, item) => sum + item.discountedPrice * item.quantity, 0);

  // Handle Checkout Navigation
  const handleCheckout = () => {
    navigate('/checkout', { state: { cart } });
  };

  return (
    <Box width={300} p={2}>
      <Typography variant="h6" mb={2}>Your Cart</Typography>

      {cart.length === 0 ? (
        <Typography variant="body1" color="textSecondary" textAlign="center">
          Your cart is empty.
        </Typography>
      ) : (
        <>
          <List>
            {cart.map((product) => (
              <ListItem key={product.id} divider>
                <ListItemText
                  primary={product.title}
                  secondary={`रु. ${product.discountedPrice} x ${product.quantity}`}
                />
                <Box display="flex" alignItems="center">
                  {/* Pass the current product to handleIncrement */}
                  <IconButton color="primary" onClick={() => handleIncrement(product)}>
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDecrement(product)}
                    disabled={product.quantity <= 1} 
                  >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>

          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography variant="h6">Total:</Typography>
            <Typography variant="h6">रु. {totalPrice}</Typography>
          </Box>

          <Button variant="contained" color="primary" fullWidth onClick={handleCheckout}>
            Checkout
          </Button>
        </>
      )}
    </Box>
  );
};



export default CartDrawer;
