import React from 'react';
import { Box, Typography, List, ListItem, IconButton, Divider } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';  // Import the Delete icon

const CartSummary = ({ cart, handleIncrement, handleDecrement, handleRemove }) => {
  const totalPrice = cart.reduce((sum, item) => sum + item.discountedPrice * item.quantity, 0);

  return (
    <Box>
      <List>
        {cart.map((product) => (
          <ListItem key={product.productId} divider>
            <Box display="flex" alignItems="center" width="100%">
              <Box flex={1}>
                <Typography variant="subtitle1">{product.title}</Typography>
                <Typography variant="body2" color="textSecondary">
                  रु. {product.discountedPrice} x {product.quantity} = रु. {product.discountedPrice * product.quantity}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                {/* Decrement Button */}
                <IconButton
                  color="primary"
                  onClick={() => handleDecrement(product.productId)} 
                  disabled={product.quantity <= 1}
                >
                  <RemoveIcon />
                </IconButton>

                {/* Quantity Display */}
                <Typography mx={1}>{product.quantity}</Typography>

                {/* Increment Button */}
                <IconButton
                  color="primary"
                  onClick={() => handleIncrement(product.productId)} 
                >
                  <AddIcon />
                </IconButton>

                {/* Delete Button */}
                <IconButton
                  color="error"  // Change color to red for delete
                  onClick={() => handleRemove(product.productId)} // Pass productId for removal
                  sx={{ ml: 2 }}  // Add some spacing for the delete button
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
      <Divider sx={{ my: 2 }} />
      <Typography variant="h6">Total: रु. {totalPrice}</Typography>
    </Box>
  );
};

export default CartSummary;
