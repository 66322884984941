import React, { useState } from 'react';
import { TextField, Button, Box, Snackbar, Alert, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';  // For enhanced notifications
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';
import { Helmet } from 'react-helmet';

const SignIn = ({ onLogin }) => {
  const [formData, setFormData] = useState({ userPhone: '', password: '' });
  const { enqueueSnackbar } = useSnackbar();  // Hook for displaying snackbars
  const navigate = useNavigate();

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://grocista.com/api/signin',
        {
          userPhone: formData.userPhone,
          password: formData.password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (response.status === 200) {
        const userData = {
          name: response.data.name,
          address: `${response.data.address}`,
          userPhone: formData.userPhone,
        };
        onLogin(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/');
      }
    } catch (error) {
      enqueueSnackbar('Invalid login credentials. Please try again.', { variant: 'error' });
    }
  };

  return (
    <>
    <Helmet>
      <title>
        Login Page || Grocists ~ Simplify Grocery Management
      </title>
    </Helmet>
    <Paper
      elevation={3}
      sx={{
        maxWidth: 400,
        mx: 'auto',
        mt: 5,
        px: 4,
        py: 6,
        borderRadius: 3,
        bgcolor: 'background.paper',
      }}
    >
      <Typography variant="h5" align="center" sx={{ mb: 3, fontWeight: 600 }}>Sign In</Typography>
      
      <form onSubmit={handleSubmit}>
        {/* Phone Number Field */}
        <TextField
          fullWidth
          label="Phone Number"
          name="userPhone"
          type="tel"
          value={formData.userPhone}
          onChange={handleChange}
          margin="normal"
          required
          InputProps={{
            startAdornment: <PhoneIcon sx={{ color: 'action.active', mr: 1 }} />,
          }}
        />
        
        {/* Password Field */}
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          margin="normal"
          required
          InputProps={{
            startAdornment: <LockIcon sx={{ color: 'action.active', mr: 1 }} />,
          }}
        />
        
        {/* Submit Button */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            textTransform: 'none',
            boxShadow: 3,
            '&:hover': {
              backgroundColor: '#007B3A',
              boxShadow: 6,
            },
          }}
        >
          Sign In
        </Button>
        
        {/* Sign Up Button */}
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            textTransform: 'none',
            borderColor: '#007B3A',
            '&:hover': {
              borderColor: '#00592D',
              backgroundColor: '#E8F5E9',
            },
          }}
          href="/register"
        >
          Sign Up
        </Button>
      </form>
      
    </Paper>
    </>
  );
};

export default SignIn;
