import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import "./Style.scss";
import { Helmet } from "react-helmet";

const NotificationPage = () => {
  const [notifications, setNotifications] = useState([]); // Default to an empty array
  const [loading, setLoading] = useState(true);

  // Placeholder data in case API fails
  const fakeNotifications = [
    {
      title: "Discount Definition",
      subtitle: "Discount Definition Texts",
      shortDesc: "What is discount formula?",
      description: "Discount is the difference between the marked price (list price) and the selling price.",
      imageUrl: "https://rpcdn.ratopati.com/media/albums/discount_bKZypBxkMz.png",
      number: 25,
      saleType: "Flash Sale",
    },
  ];

  useEffect(() => {
    // Fetch notifications from API
    axios
      .get("http://grocista.com/api/notifications")
      .then((response) => {
        const data = response.data;
        if (Array.isArray(data)) {
          setNotifications(data); // Set API response if it's an array
        } else {
          setNotifications(fakeNotifications); // Use fallback if API response is invalid
        }
      })
      .catch(() => {
        setNotifications(fakeNotifications); // Use fallback if API fails
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>Notifications || Grocista ~ Simplify Grocery Management</title>
      </Helmet>
      <div className="notification-page">
        {loading ? (
          <div className="loading-spinner">
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={3} className="notification-grid">
            {notifications.map((notification, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card className="notification-card" sx={{ position: "relative" }}>
                  {/* Badge for Sale Percentage */}
                  <Box
                    className="notification-badge"
                    sx={{
                      position: "absolute",
                      top: "-25px",
                      left: "-25px",
                      zIndex: 1,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      border: "2px solid red",
                      color: "red",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                    }}
                  >
                    UpTo -{notification.number}%
                  </Box>

                  {/* Notification Image */}
                  {notification.imageUrl && (
                    <CardMedia
                      component="img"
                      alt={notification.title}
                      className="notification-image"
                      height="180"
                      image={notification.imageUrl}
                      sx={{ borderBottom: "1px solid #ddd" }}
                    />
                  )}

                  {/* Notification Content */}
                  <CardContent className="notification-content">
                    {/* Title */}
                    <Typography variant="h6" component="div" className="notification-title">
                      {notification.title}
                    </Typography>

                    {/* Sale Type */}
                    <Typography
                      variant="subtitle2"
                      className="notification-sale-type"
                      sx={{
                        color: "primary.main",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        mb: 1,
                      }}
                    >
                      {notification.saleType}
                    </Typography>

                    {/* Short Description */}
                    <Typography
                      style={{ fontWeight: "600" }}
                      variant="body2"
                      color="textSecondary"
                      className="notification-desc"
                    >
                      {notification.shortDesc}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className="notification-desc">
                      {notification.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </>
  );
};

export default NotificationPage;
